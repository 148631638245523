import axios from '@/plugins/axios'
import { AxiosError } from 'axios'

class LabFHIR {
    static async getInformeHC(row: {_id:string}): Promise<any[]> {
        try {
            const { data } = await axios.get('/kern/get-informe-hc', {
                params: { id: row._id }
            })
            if(data?.tipo === 'BIOBOX') {
                const baseURL = axios.defaults.baseURL
                data.estudio.informe = `${baseURL}/get-url?url=${data.estudio.informeurl}`
            }
            return data
        } catch (error) {
            const err = error as AxiosError
            console.log(err)
            throw new Error(err.message)
        }
    }
}

export default LabFHIR